import { Form, Popup } from 'devextreme-react';
import { ButtonItem, CompareRule, Item, Label, RequiredRule } from 'devextreme-react/form';
import { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { useApiProfile } from './api';
import { useSelector, useDispatch } from 'react-redux';
import notify from 'devextreme/ui/notify';
import { putProfile } from 'src/redux/slices/authJwt';

export const ModalChangePassword = ({ visible, setVisible, allowClose }) => {
  const [data, setData] = useState({ password: '', passwordConfirm: '' });
  const { innerWidth } = window;
  const { changePassword } = useApiProfile();
  const { user } = useSelector((state) => state.authJwt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setData({ password: '', passwordConfirm: '' });
    }
  }, [visible]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      changePassword(user.idUser, data.password, data.passwordConfirm).then((res) => {
        if (res) {
          dispatch(putProfile(res));
          notify('Contraseña cambiada correctamente', 'success', 5000);                    
          setVisible()
          return;
        }
        notify('Error al cambiar la contraseña', 'error', 5000);
      });
    },
    [data,setVisible]
  );

  return (
    <Popup onHiding={setVisible} width={600} title={'Cambiar contraeña'} showCloseButton={allowClose} fullScreen={innerWidth < 622} height={'auto'} visible={visible}>
      {!allowClose && (
        <div className='alertPass'>
          <p>
            <strong>Atención!</strong>
          </p>
          <p>Por seguridad debe realizar el cambio de la contraseña antes de continuar</p>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Form formData={data} labelMode='floating' labelLocation='top'>
          <Item dataField={'password'} editorOptions={{ mode: 'password' }}>
            <Label text={'Nueva contraseña'} />
            <RequiredRule message={'Nueva contraseña requerida'} />
          </Item>
          <Item dataField={'passwordConfirm'} editorOptions={{ mode: 'password' }}>
            <Label text={'Confirme la nueva contraseña'} />
            <RequiredRule message={'Confirmación requerida'} />
            <CompareRule
              message={'Las contraseñas no coiciden'}
              comparisonTarget={() => {
                return data.password;
              }}
            />
          </Item>
          <ButtonItem buttonOptions={{ text: 'CAMBIAR', type: 'default', useSubmitBehavior: true, width: '120px' }} />
        </Form>
      </form>
    </Popup>
  );
};
