import notify from 'devextreme/ui/notify';
import runAxios from 'src/utils/axiosPetitions';

export const useApiProfile = () => {
  const getData = (idUser) => {
    return runAxios('authentication/getProfile', 'get', null, {idUser}).then((res) => {
      if (res.internalCode >= 20) {
        notify(res.message, 'error', 5000);
        return null;
      }      
      return res.responseDetail;
    });
  };

  const changePassword = (idUser, password, passwordConfirm) => {
    return runAxios('authentication/changePassword', 'get', null, {idUser, password, passwordConfirm}).then((res) => {
      if (res.internalCode >= 20) {
        notify(res.message, 'error', 5000);
        return null;
      }      
      return res.responseDetail;
    });
  };

  return { getData, changePassword };
};
