import React, { useState, useEffect, useRef } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { Button, Toolbar, Tooltip } from 'devextreme-react';
import { TextArea } from 'devextreme-react/text-area';
import List, { MenuItem } from 'devextreme-react/list';

import runAxios, { runAxiosWithFile } from 'src/utils/axiosPetitions';
import 'devextreme-react/text-area';
import ScrollView from 'devextreme-react/scroll-view';
import '../../styles.css';
import moment from 'moment';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { confirm } from 'devextreme/ui/dialog';
import Notify from 'devextreme/ui/notify';
import { Item } from 'devextreme-react/toolbar';

const Index = ({ visible, setVisible, data, idUser, areas }) => {
  const guardarCambios = () => {};
  const [currentFile, setCurrentFile] = useState(null);
  const [idArea, setIdArea] = useState(-1);
  const [tt_Id, setTt_Id] = useState(-1);
  const [lstSeguimiento, setLstSeguimiento] = useState([]);
  const [load, setLoad] = useState(true);
  const [seguimiento, setSeguimiento] = useState('');
  const [loading, setLoading] = useState(false);
  const usuarios = useSelector((x) => x.initialConfiguration.users);
  const tiposTicket = useSelector((x) => x.initialConfiguration.tiposTickets);
  const fileInputRef = useRef(null);

  const [modalTransfer, setModalTransfer] = useState({
    visible: false,
    value: '',
    idnewArea: -1,
  });
  const [item, setItem] = useState({});
  const { user } = useSelector((state) => state.authJwt);
  const cancelarCambios = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setIdArea(data.ar_Id);
    setTt_Id(data.tt_Id);
    setItem(data);
    getSeguimiento(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const ESTADOS = [
    { label: 'ABIERTO', value: 1 },
    { label: 'PROGRESO', value: 2 },
    { label: 'CERRADO', value: 3 },
  ];

  const getSeguimiento = (ticket) => {
    setLoad(true);
    runAxios('Tickets/getSeguimiento', 'get', null, { idTicket: ticket.tk_Id })
      .then((res) => {
        if (res.internalCode >= 20) {
          Notify({
            message: 'Se ha presentado un error al consultar la información de los seguimientos',
            type: 'error',
            position: 'bottom center',
          });
          return;
        }
        setLstSeguimiento(res.responseDetail);
      })
      .catch((err) => {
        console.log(err);
        Notify({
          message: 'Se ha presentado un error al consultar la información de los seguimientos',
          type: 'error',
          position: 'bottom center',
        });
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf' && file.size <= 5000000) {
      setCurrentFile(file);
    } else {
      // Mostrar un mensaje de error si el archivo no cumple con los requisitos
      alert('El archivo debe ser un PDF y no debe exceder los 5MB.');
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const formValueChange = (e) => {
    if (e.value === null) {
      return;
    }
    switch (e.dataField) {
      case 'tt_Id':
        changeTypeTicket(e.value);
        break;
      case 'eD_Us_Id':
        if (item.ar_Id !== user.idProfile) {
          Notify({ message: 'No se puede asignar un ticket que no corresponde a su área, primero transfiera el ticket a su área y luego asigne el ticket', type: 'info', position: 'bottom center' });
          setItem((old) => ({ ...old, eD_Us_Id: null }));
          return;
        }

        if (usuarios.filter((x) => x.us_Id === e.value && x.us_Ar_Id === user.idProfile).length === 0) {
          Notify({ message: 'No puede transferir un caso a un agente que pertenece a otra área', type: 'info', position: 'bottom center' });
          setItem((old) => ({ ...old, eD_Us_Id: null }));
          return;
        }
        AssignTicket(e.value);
        break;
      case 'ar_Id':
        transferTicket(e.value);
        break;
      default:
        break;
    }
  };

  const AssignTicket = (newUsr) => {
    let result = confirm('<i>Seguro que se desea asignar este ticket?</i>', 'Confirmar acción');
    result.then((res) => {
      if (res) {
        runAxiosWithFile('Tickets/changeStatus', 'post', null, null, { idTicket: item.tk_Id, idUser: newUsr, idUserEx: user.idUser, status: 2, nota: 'Asignación ticket' })
          .then((res) => {
            if (res.internalCode >= 20) {
              Notify({ message: 'Se ha presentado un error al asignar el ticket', type: 'error', position: 'bottom center' });
              return;
            }
            getSeguimiento(item);
            setItem((old) => ({ ...old, eD_Us_Id: newUsr }));
          })
          .catch((err) => {
            console.log(err);
            Notify({ message: 'Se ha presentado un error al asignar el ticket', type: 'error', position: 'bottom center' });
          });
      } else {
        setItem((old) => ({ ...old, eD_Us_Id: null }));
      }
    });
  };

  const cancelTransfer = () => {
    setModalTransfer({ value: '', visible: false, idnewArea: -1 });
    setItem((old) => ({ ...old, ar_Id: idArea }));
  };

  const confirmTransfer = () => {
    if (!modalTransfer.value) {
      Notify({ message: 'Debe ingresar un motivo', type: 'info', position: 'bottom center' });
      return;
    }
    runAxios('Tickets/transferTicket', 'post', null, {
      idTicket: item.tk_Id,
      idArea: modalTransfer.idnewArea,
      idUser: idUser,
      oldType: tt_Id,
      motivo: modalTransfer.value,
    })
      .then((res) => {
        if (res.internalCode >= 20) {
          Notify({ message: 'Se ha presentado un error al transferir el ticket', type: 'error', position: 'bottom center' });
          return;
        }
        Notify({ message: 'Ticket transferido correctamente', type: 'success', position: 'bottom center' });
        setModalTransfer({ value: '', visible: false, idnewArea: -1 });
        cancelarCambios();
      })
      .catch((err) => {
        console.log(err);
        Notify({ message: 'Se ha presentado un error al transferir el ticket', type: 'error', position: 'bottom center' });
      });
  };

  const transferTicket = (newAr) => {
    setModalTransfer({ value: '', visible: true, idnewArea: newAr });
  };

  const changeTypeTicket = (newTipe) => {
    let result = confirm('<i>Seguro que desea cambiar el tipo de atención?</i>', 'Confirmar acción');
    result.then((res) => {
      if (res) {
        runAxios('Tickets/changeTypeTicket', 'post', null, {
          idTicket: item.tk_Id,
          idUser: idUser,
          oldType: tt_Id,
          newType: newTipe,
        })
          .then((res) => {
            if (res.internalCode >= 20) {
              Notify({
                message: 'Se ha presentado un error al cambiar tipo de atención',
                type: 'error',
                position: 'bottom center',
              });
              return;
            }
            Notify({
              message: 'Tipo de atención cambiado',
              type: 'success',
              position: 'bottom center',
            });
            setTt_Id(newTipe);
            getSeguimiento(item);
          })
          .catch((err) => {
            console.log(err);
            Notify({
              message: 'Se ha presentado un error al cambiar tipo de atención',
              type: 'error',
              position: 'bottom center',
            });
          });
      }
    });
  };

  const addSeguimiento = () => {
    if (!seguimiento) {
      Notify({ message: 'Debe agregar un seguimiento', type: 'info', position: 'bottom center' });
      return;
    }
    if (!item.eD_Us_Id) {
      AssignTicket(user.idUser);
    }
    setLoading(true);
    runAxiosWithFile('Tickets/addSeg', 'post', currentFile, null, { idTicket: item.tk_Id, idUser: idUser, nota: seguimiento })
      .then((res) => {
        if (res.internalCode >= 20) {
          Notify({ message: 'Se ha presentado un error al añadir el seguimiento', type: 'error', position: 'bottom center' });
          return;
        }
        setSeguimiento('');
        Notify({ message: 'Seguimiento agregado', type: 'success', position: 'bottom center' });
        getSeguimiento(item);
      })
      .catch((err) => {
        console.log(err);
        Notify({ message: 'Se ha presentado un error al añadir el seguimiento', type: 'error', position: 'bottom center' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const finalizarSeguimiento = () => {
    if (!seguimiento) {
      Notify({ message: 'Debe agregar un seguimiento', type: 'info', position: 'bottom center' });
      return;
    }
    if (!item.eD_Us_Id) {
      AssignTicket(user.idUser);
    }
    setLoading(true);
    runAxiosWithFile('Tickets/changeStatus', 'post', currentFile, null, { idTicket: item.tk_Id, idUser: idUser, status: 3, nota: seguimiento })
      .then((res) => {
        if (res.internalCode >= 20) {
          Notify({ message: 'Se ha presentado un error al asignar el ticket', type: 'error', position: 'bottom center' });
          return;
        }
        getSeguimiento(item);
        setSeguimiento('');
        cancelarCambios();
      })
      .catch((err) => {
        console.log(err);
        Notify({
          message: 'Se ha presentado un error al asignar el ticket',
          type: 'error',
          position: 'bottom center',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteAttach = () => {
    setCurrentFile(null);
  };

  return (
    <Popup visible={visible} onHiding={cancelarCambios} dragEnabled={false} hideOnOutsideClick={true} showCloseButton={false} showTitle={true} title='SEGUIMIENTO TICKET' width='90%' height='90%'>
      <Position at='center' my='center' />
      <ScrollView width='100%' height='100%'>
        <Form formData={item} labelLocation='top' labelMode='static' onFieldDataChanged={formValueChange}>
          <GroupItem cssClass='first-group' colCount={2}>
            <GroupItem>
              <SimpleItem dataField={'tk_Id'} editorOptions={{ readOnly: true }} colSpan={2}>
                <Label text={'ID'} />
              </SimpleItem>
              <SimpleItem dataField={'tk_Estado'} editorType='dxSelectBox' editorOptions={{ searchEnabled: true, readOnly: true, items: ESTADOS, displayExpr: 'label', valueExpr: 'value' }}>
                <Label text={'ESTADO'} />
              </SimpleItem>
              <SimpleItem dataField={'cl_Nombre'} editorOptions={{ readOnly: true }}>
                <Label text={'CLIENTE'} />
              </SimpleItem>
              <SimpleItem dataField={'cd_Nombre'} editorOptions={{ readOnly: true }}>
                <Label text={'CIUDAD'} />
              </SimpleItem>
              <SimpleItem dataField={'us_Nombre'} editorOptions={{ readOnly: true }}>
                <Label text={'CREADO POR'} />
              </SimpleItem>
              <SimpleItem dataField={'eD_Us_Id'} editorType='dxSelectBox' editorOptions={{ readOnly: item.tk_UsCierre !== null, searchEnabled: true, items: usuarios, displayExpr: 'us_Nombre', valueExpr: 'us_Id' }}>
                <Label text={'ASIGNADO A'} />
              </SimpleItem>
            </GroupItem>
            <GroupItem>
              <SimpleItem
                dataField={'ar_Id'}
                editorType='dxSelectBox'
                editorOptions={{
                  searchEnabled: true,
                  readOnly: item.tk_UsCierre !== null,
                  items: areas,
                  displayExpr: 'ar_Nombre',
                  valueExpr: 'ar_Id',
                }}>
                <Label text={'ÁREA'} />
              </SimpleItem>

              <SimpleItem dataField={'af_Nombre'} editorOptions={{ readOnly: true }}>
                <Label text={'ACTIVO FIJO'} />
              </SimpleItem>
              <SimpleItem
                dataField={'tt_Id'}
                editorType='dxSelectBox'
                editorOptions={{
                  readOnly: item.tk_UsCierre !== null,
                  searchEnabled: true,
                  items: tiposTicket,
                  displayExpr: 'tt_Nombre',
                  valueExpr: 'tt_Id',
                }}>
                <Label text={'TIPO'} />
              </SimpleItem>

              <SimpleItem dataField={'tk_Titulo'} editorOptions={{ readOnly: true }}>
                <Label text={'TITULO'} />
              </SimpleItem>

              <SimpleItem dataField={'tk_FechaCrea'} editorOptions={{ readOnly: true }} editorType='dxDateBox'>
                <Label text={'FECHA CREA.'} />
              </SimpleItem>
            </GroupItem>
            <SimpleItem colSpan={2} dataField='tk_Descripcion' editorType='dxTextArea' editorOptions={{ height: 140, readOnly: true }}>
              <Label text={'DESCRIPCIÓN'} />
            </SimpleItem>
          </GroupItem>
        </Form>
        {item && !item.tk_UsCierre ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextArea
                value={seguimiento}
                onValueChange={(e) => {
                  setSeguimiento(e);
                }}
                style={{ flexGrow: 1, margin: 5, height: 100 }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 5,
                }}></div>
            </div>
            <input type='file' accept='.pdf' onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
            {currentFile && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <i className='dx-icon dx-icon-attach' style={{ marginRight: 5 }}></i>
                <span>{currentFile.name.length > 30 ? `${currentFile.name.substring(0, 30)}...` : currentFile.name}</span>
                <i className='dx-icon dx-icon-close' style={{ color: 'red', marginLeft: 5, borderStyle: 'solid', borderWidth: 1, padding: 1, borderRadius: 5 }} onClick={handleDeleteAttach}></i>
              </div>
            )}
            <div style={{ marginLeft: 7 }}>
              <Toolbar>
                <Item location='before' widget='dxButton' locateInMenu='auto' showText='inMenu'>
                  <Button icon='attach' type='danger' text={'Adjuntar archivo'} onClick={handleButtonClick} />
                </Item>
                <Item location='before' widget='dxButton' locateInMenu='auto' showText='inMenu'>
                  <Button text={loading ? 'CARGANDO...' : 'SEGUIMIENTO'} icon='runner' disabled={loading} onClick={addSeguimiento} />
                </Item>
                <Item location='before' widget='dxButton' locateInMenu='auto' showText='inMenu'>
                  <Button text={loading ? 'CARGANDO...' : 'FINALIZAR'} icon='check' onClick={finalizarSeguimiento} disabled={loading} type={'success'} />
                </Item>
              </Toolbar>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {
          <div className='text-content'>
            <Typography variant='h6' sx={{ margin: 2 }} color={'#027BF1'}>
              SEGUIMIENTOS
            </Typography>
            {load ? (
              <div className='contain'>
                <LoadIndicator id='large-indicator' />
              </div>
            ) : (
              lstSeguimiento.map((x) => geSeguimientoComponent(x))
            )}
          </div>
        }
      </ScrollView>
      <ToolbarItem widget='dxButton' toolbar='bottom' location='before' options={{ icon: 'save', text: 'GUARDAR', onClick: guardarCambios }} />
      <ToolbarItem widget='dxButton' toolbar='bottom' location='before' options={{ icon: 'remove', text: 'CERRAR', onClick: cancelarCambios }} />
      <Popup
        visible={modalTransfer.visible}
        onHiding={() => {
          setModalTransfer((old) => ({ ...old, visible: false }));
        }}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        width={350}
        height={280}
        title='CONFIRMAR ACCIÓN'>
        <TextArea
          value={modalTransfer.value}
          onValueChange={(e) => {
            setModalTransfer((old) => ({ ...old, value: e }));
          }}
        />
        <i>Ingrese el motivo por el cual está transfiriendo el ticket</i>
        <ToolbarItem widget='dxButton' toolbar='bottom' location='before' options={{ icon: 'check', text: 'CONFIRMAR', onClick: confirmTransfer }} />
        <ToolbarItem widget='dxButton' toolbar='bottom' location='before' options={{ icon: 'remove', text: 'CANCELAR', onClick: cancelTransfer }} />
      </Popup>
    </Popup>
  );
};

function geSeguimientoComponent(item) {  
  return (
    <div key={item.st_Id} className='chat-message-incoming'>
      <div className='chat-message-author'>{item.us_Nombre} </div>
      <div className='chat-message-date'> ({moment(item.st_Fecha).format('DD/MM/YYYY hh:mm a')})</div>
      {item.st_Adjunto && (
        <div
          id='download'
          className='chat-message-attach'
          onClick={() => {
            handleDownLoad(item.st_Adjunto, item.st_Id);
          }}
          style={{ cursor: 'pointer' }}>
          !<i className='dx-icon dx-icon-attach' style={{ color: 'rgb(128, 0, 0)' }}></i>{' '}
        </div>
      )}
      <div className='chat-message-text'>{item.st_Detalle}</div>
      <Tooltip showEvent='mouseenter' hideEvent='mouseleave' position={'top'} target={'#download'}>
        Descargar archivo adjunto
      </Tooltip>
    </div>
  );
}

const handleDownLoad = (fileData, ticketId) => {
  // Decodificar el archivo de base64 a un arreglo de bytes
  const byteCharacters = atob(fileData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Crear un Blob a partir del arreglo de bytes
  const blob = new Blob([byteArray], { type: 'application/pdf' }); // Reemplaza 'application/pdf' con el tipo MIME correcto del archivo

  // Crear un objeto URL para el Blob
  const fileURL = URL.createObjectURL(blob);

  // Crear un enlace temporal para descargar el archivo
  const link = document.createElement('a');
  link.href = fileURL;

  // Obtener el nombre del archivo del Blob
  const fileName = `ticket-${ticketId}.pdf`; // Reemplaza "nombre_del_archivo.pdf" con el nombre adecuado del archivo

  link.setAttribute('download', fileName); // Establecer el nombre del archivo para la descarga
  document.body.appendChild(link);

  // Simular el clic en el enlace para iniciar la descarga
  link.click();

  // Limpiar el objeto URL y el enlace temporal
  URL.revokeObjectURL(fileURL);
  document.body.removeChild(link);
};

export default Index;
