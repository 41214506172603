import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { makeStyles } from '@material-ui/core/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton } from '@material-ui/core';
// import Search from './Search';
import ModalAdminTicket from 'src/components/Modales/Tickets/SingleTicketAdmin';
import Account from './Account';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import { useSelector } from 'react-redux';
import runAxios from 'src/utils/axiosPetitions';
import notify from 'devextreme/ui/notify';
import { NumberBox } from 'devextreme-react';
import { ModalChangePassword } from 'src/views/DashboardAppView/Profile/ModalChangePassword';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 40;
const APPBAR_DESKTOP = 50;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',

    backdropFilter: 'blur(8px)',
    backgroundColor: '#eee',
    padding: 3,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: DRAWER_WIDTH,
    },
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
    },
  },
}));

// ----------------------------------------------------------------------

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string,
};

function TopBar({ onOpenNav, className }) {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [valueToFind, setValueToFind] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const { user } = useSelector((state) => state.authJwt);
  const [modalChPssVisible, setModalChPssVisible] = useState(false);

  useEffect(() => {
    if (user.changePass) {
      handleChangePassword();
    }
  }, []);

  const handleChangePassword = useCallback(() => {
    setModalChPssVisible(true);
  }, [setModalChPssVisible]);

  const handleCloseModal = useCallback(() => {
    setModalChPssVisible(false);
  }, [setModalChPssVisible]);

  const handleClicFind = async () => {
    if (!valueToFind || valueToFind === '0') {
      notify('Debe ingresar un Id de ticket', 'info', 5000);
      return;
    }
    const resp = await runAxios('tickets', 'get', null, { id: valueToFind });
    if (resp.internalCode !== 4) {
      notify(`No se encontró el ticket con ID ${valueToFind}`, 'info', 5000);
      return;
    }
    await setSelectedItem(resp.responseDetail[0]);
    setModal(true);
    setValueToFind(null);
  };

  const handleChangeFind = useCallback(
    (e) => {
      setValueToFind(e);
    },
    [setValueToFind]
  );

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
            }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <NumberBox labelMode='static' value={valueToFind} max={9999} defaultValue={null} label='Buscar ticket' onValueChange={handleChangeFind}>
          <TextBoxButton
            name='password'
            location='after'
            options={{
              icon: 'find',
              stylingMode: 'text',
              onClick: handleClicFind,
            }}
          />
        </NumberBox>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3,
              },
            },
          }}>
          <Account />
        </Box>
      </Toolbar>
      <ModalChangePassword allowClose={false} visible={modalChPssVisible} setVisible={handleCloseModal} />
      <ModalAdminTicket visible={modal} data={selectedItem} setVisible={setModal} idUser={user.idUser} />
    </AppBar>
  );
}

export default TopBar;
