import React from 'react';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';
import { MIcon } from 'src/theme';
import { LocalHospital, Engineering, Dashboard, CalendarToday, Business, PriceCheck } from '@material-ui/icons';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  page: <MIcon src={path('ic_page')} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'opciones',
    items: [
      {
        title: 'Principal',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root,
      },
      {
        title: 'Clientes',
        icon: <LocalHospital />,
        href: PATH_APP.general.clientes,
      },
      {
        title: 'Viaticos',
        icon: <PriceCheck />,        
        href: PATH_APP.general.viaticos,
        items: [
          {
            title: 'Administración',
            href: PATH_APP.general.viaticosAutorizaciones,
          },
          {
            title: 'Contabilidad',
            href: PATH_APP.general.viaticosContabilidad,
          },
          {
            title: 'Mis viaticos',
            href: PATH_APP.general.viaticosMisViaticos,
          },
        ],
      },
      {
        title: 'Planeador',
        icon: <CalendarToday />,
        href: PATH_APP.general.planeador,
        items: [
          {
            title: 'Calendario',
            href: PATH_APP.general.calendar,
          },
        ],
      },
      {
        title: 'CRM',
        icon: <Business />,
        href: PATH_APP.general.crm,
      },
      {
        title: 'Tableros',
        icon: <Dashboard />,
        href: PATH_APP.general.tableros,
      },
      {
        title: 'Operaciones',
        icon: <Engineering />,
        href: PATH_APP.general.Operaciones,
        items: [
          {
            title: 'INSTALACIONES',
            href: PATH_APP.general.OperacionesInstalaciones,
          },
          {
            title: 'PROCESOS',
            href: PATH_APP.general.OperacionesProcesos,
          },
          {
            title: 'LICENCIAS',
            href: PATH_APP.general.licencias,
          },
        ],
      },
      // {
      //   title: 'Cerrar sesión',
      //   icon: <Logout />,
      //   href: PATH_PAGE.auth.logout,
      // },
    ],
  },
];

export default navConfig;
