import clsx from 'clsx';
import { Icon } from '@iconify/react';
import React, { useRef, useState } from 'react';
import homeFill from '@iconify-icons/eva/home-fill';
import PopoverMenu from 'src/components/PopoverMenu';
import { Link as RouterLink } from 'react-router-dom';
import personFill from '@iconify-icons/eva/person-fill';
import settings from '@iconify-icons/eva/settings-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, Avatar, Button, Divider, MenuItem, Typography } from '@material-ui/core';
import { MIconButton } from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/redux/slices/authJwt';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Principal',
    icon: homeFill,
    linkTo: '/',
  },
  {
    label: 'Perfil',
    icon: personFill,
    linkTo: '/profile',
  },
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5),
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      background: alpha(theme.palette.grey[900], 0.8),
    },
  },
}));

// ----------------------------------------------------------------------

function Account() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { user } = useSelector((state) => state.authJwt);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSession = () => {
    dispatch(logout());
  };

  return (
    <>
      <MIconButton ref={anchorRef} onClick={handleOpen} className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}>
        <Avatar alt='' src={'/static/images/BactecH.png'} />
      </MIconButton>

      <PopoverMenu width={220} open={isOpen} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {user.nombre}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user.nombreProfile}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose} className={classes.menuItem}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        {user.idProfile === 1 && (
          <MenuItem to={'/preferences'} component={RouterLink} onClick={handleClose} className={classes.menuItem}>
            <Box
              component={Icon}
              icon={settings}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {'Configuracion'}
          </MenuItem>
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color='inherit' variant='outlined' onClick={handleCloseSession}>
            Cerrar sesión
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
