// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: '/',
  config: '/cpanel',
  auth: '/auth',
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),    
    logout: path(ROOTS.auth, '/logout'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  clientInstallationById:'clientInstallationById',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance'
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, ''),
    clientes: path(ROOTS.app, 'clientes'),
    activosPorCliente: path(ROOTS.app, 'clientes/activosPorCliente'),
    clientesTicketHistory: path(ROOTS.app, 'clientes/ticketsHistory'),
    Operaciones:path(ROOTS.app,'procesos'),
    OperacionesProcesos:path(ROOTS.app,'procesos/procesos'),
    OperacionesSubProcesos:path(ROOTS.app,'procesos/procesos/subProcesos'),
    OperacionesListasChequeo:path(ROOTS.app,'procesos/procesos/listasChequeo'),
    OperacionesInstalaciones:path(ROOTS.app,'procesos/instalaciones'),
    OperacionesInstalacionesPorActivo:path(ROOTS.app,'procesos/instalaciones/porActivoFijo'),
    OperacionesInstalacionesPorActivoAvance:path(ROOTS.app,'procesos/instalaciones/porActivoFijo/avance'),
    dashboard: path(ROOTS.app, ''),
    business: path(ROOTS.app, 'business'),    
    businessDetails: path(ROOTS.app, 'business/details'),   
    licencias: path(ROOTS.app,'licencias'),
    tableros: path(ROOTS.app,'dashboards'),
    planeador:path(ROOTS.app,'planeador'),
    calendar:path(ROOTS.app,'calendar'),
    viaticos:path(ROOTS.app,'viaticos'),
    viaticosAutorizaciones:path(ROOTS.app,'viaticosAutorizaciones'),
    viaticosContabilidad:path(ROOTS.app,'viaticosContabilidad'),
    viaticosMisViaticos:path(ROOTS.app,'viaticosMisViaticos'),
    crm:path(ROOTS.app,'crm'),
    profile:path(ROOTS.app,'profile'),
    preferences:path(ROOTS.app,'preferences'),
  },
};