import { createSlice } from '@reduxjs/toolkit';
import runAxios from 'src/utils/axiosPetitions';
import jwtDecode from 'jwt-decode';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
  error: null,
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.error = null;
    },
    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    pubProfile(state, action) {
      state.user = action.payload;
    },
    logoutSuccess(state) {
      state.error = null;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export function login({ user, password }) {
  console.log(user, password);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await runAxios('authentication/login', 'get', null, { user, password })
      .then((res) => {
        if (res.internalCode >= 20) {
          dispatch(slice.actions.hasError(res.messageDetails));
        } else {
          //console.log(res.responseDetail)
          //const { token, userInfo } = res.responseDetail;
          //setSession(token);
          dispatch(slice.actions.loginSuccess(res.responseDetail));
        }
      })
      .catch((err) => {
        dispatch(slice.actions.hasError(err));
      });
  };
}

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function putProfile(data) {
  return async (dispatch) => {
    dispatch(slice.actions.pubProfile(data));
  };
}

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        //const response = await axios.get('/api/account/my-account');
        const response = null;
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user,
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        })
      );
    }
  };
}
