import axios from 'axios';

export default function runAxios(url, method, body, params) {
  const token = localStorage.getItem('accessToken');
  const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.API_URL;
  const promise = new Promise((resultado, error) => {
    let info = {
      method: '',
      headers: { authorization: token },
      url: `${API_URL}${url}`,
      data: {},
      params: {},
    };
    try {
      switch (method.toUpperCase()) {
        case 'GET':
          info.method = 'GET';
          info.params = params;
          info.data = body;
          break;
        case 'POST':
          info.method = 'POST';
          info.params = params;
          info.data = body;
          break;
        case 'PUT':
          info.method = 'PUT';
          info.params = params;
          info.data = body;

          break;
        case 'DELETE':
          info.method = 'DELETE';
          info.params = params;
          break;
        default:
          error(`500`);
          break;
      }
      axios(info, { withCredentials: true })
        .then((result) => {
          //   if (result.data.internalCode >=20) {
          //     error(result.data.messageDetails)
          //     return;
          //   }
          resultado(result.data);
        })
        .catch((err) => {
          error(err);
        });
    } catch (error) {
      error(`Error al procesar la información`);
    }
  });
  return promise;
}

export const runAxiosWithFile = (url, method, file, data, params) => {
  const formData = new FormData();
  formData.append('file', file); // Agregar el archivo adjunto al FormData
  const token = localStorage.getItem('accessToken');
  const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.API_URL;

  // Agregar los demás datos al FormData
  for (let key in data) {
    formData.append(key, data[key]);
  }

  return axios({
    method: method,
    url: `${API_URL}${url}`,
    data: formData, // Usar el FormData para enviar los datos y el archivo adjunto
    params: params,
    headers: {
      authorization: token,
      'Content-Type': 'multipart/form-data', // Establecer el encabezado adecuado para el envío de archivos
    },
  });
};
