import { createSlice } from '@reduxjs/toolkit';
import runAxios from 'src/utils/axiosPetitions';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  error: null,
  lstError: null,
  order: null,
  listOrders: null,
  anamnesisDet: null,
  pending: false,
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    changeLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasErrorOrder(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorLstOrders(state, action) {
      state.isLoading = false;
      state.lstError = action.payload;
    },
    getOrder(state, action) {
      state.isLoading = false;
      state.error = null;
      state.order = action.payload;
    },
    clearAnamnesis(state) {
      state.isLoading = false;
      state.error = null;
      state.order = null;
      state.anamnesisDet = null;
    },
    getAnamnesisDet(state, action) {
      state.isLoading = false;
      state.error = null;
      state.anamnesisDet = action.payload;
    },
    getLstOrders(state, action) {
      state.isLoading = false;
      state.listOrders = null;
      state.listOrders = action.payload;
    },
    setPending(state, action) {
      state.pending = action.payload;
    },
  },
});

export default slice.reducer;

export function getLstOrders(idOrd, fInicio, fFinal) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await runAxios('anamnesis/getOrders', 'get', null, { idOrd, fInicio, fFinal })
      .then((res) => {
        if (res.internalCode >= 20) {
          //Error
          dispatch(slice.actions.hasErrorOrder(res.messageDetails));
        } else {
          //Success
          dispatch(slice.actions.getLstOrders(res.responseDetail.data));
        }
      })
      .catch((err) => {
        dispatch(slice.actions.hasErrorOrder(err));
      });
  };
}

export function getOrder(infoOrd) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await runAxios('/anamnesis/getAnamnesis', 'GET', null, { idOrd: infoOrd.Ord_Id })
      .then((res) => {
        dispatch(slice.actions.getAnamnesisDet(res.responseDetail.data));
        dispatch(slice.actions.getOrder(infoOrd));
      })
      .catch((err) => {
        dispatch(slice.actions.hasErrorOrder(err));
      });
  };
}

export function clearAnamnesis() {
  return async (dispatch) => {
    dispatch(slice.actions.clearAnamnesis());
  };
}

export function changeLoading(status) {
  return async (dispatch) => {
    dispatch(slice.actions.changeLoading(status));
  };
}

export function setPending(status) {
  return async (dispatch) => {
    dispatch(slice.actions.setPending(status));
  };
}
