import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import configReducer from './slices/initialConfig'
import authJwt from './slices/authJwt'
import orders from './slices/order'
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',

  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings', 'initialConfiguration','authJwt']
};

const rootReducer = combineReducers({
  initialConfiguration: configReducer,
  authJwt: authJwt,
  settings: settingsReducer,
  orders: orders
});

export { rootPersistConfig, rootReducer };
