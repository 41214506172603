import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AuthProtect from 'src/components/Auth/AuthProtect';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: '/',
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/DashboardAppView')),
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />,
    },
    {
      exact: true,
      path: PATH_APP.general.clientes,
      component: lazy(() => import('src/views/DashboardAppView/Clientes')),
    },
    {
      exact: true,
      path: PATH_APP.general.activosPorCliente,
      component: lazy(() => import('src/views/DashboardAppView/ActivosFijos')),
    },
    {
      exact: true,
      path: PATH_APP.general.clientesTicketHistory,
      component: lazy(() => import('src/views/DashboardAppView/Clientes/ticktsHistory')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesProcesos,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/PROCESOS')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesSubProcesos,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/PROCESOS/SubProcesos')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesListasChequeo,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/PROCESOS/ListasChequeo')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesInstalaciones,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/INSTALACIONES')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesInstalacionesPorActivo,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/INSTALACIONES/PorActivoFijo')),
    },
    {
      exact: true,
      path: PATH_APP.general.OperacionesInstalacionesPorActivoAvance,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/INSTALACIONES/PorActivoFijoAvance')),
    },
    {
      exact: true,
      path: PATH_APP.general.licencias,
      component: lazy(() => import('src/views/DashboardAppView/Operaciones/LICENCIAS')),
    },
    {
      exact: true,
      path: PATH_APP.general.tableros,
      component: lazy(() => import('src/views/DashboardAppView/Tableros')),
    },
    {
      exact: true,
      path: PATH_APP.general.calendar,
      component: lazy(() => import('src/views/DashboardAppView/Calendario')),
    },
    {
      exact: true,
      path: PATH_APP.general.crm,
      component: lazy(() => import('src/views/DashboardAppView/CRM')),
    },
    {
      exact: true,
      path: PATH_APP.general.viaticosAutorizaciones,
      component: lazy(() => import('src/views/DashboardAppView/Viaticos/Autorizaciones')),
    },
    {
      exact: true,
      path: PATH_APP.general.viaticosContabilidad,
      component: lazy(() => import('src/views/DashboardAppView/Viaticos/Contabilidad')),
    },
    {
      exact: true,
      path: PATH_APP.general.viaticosMisViaticos,
      component: lazy(() => import('src/views/DashboardAppView/Viaticos/MisViaticos')),
    },
    {
      exact: true,
      path: PATH_APP.general.profile,
      component: lazy(() => import('src/views/DashboardAppView/Profile')),
    },
    {
      exact: true,
      path: PATH_APP.general.preferences,
      component: lazy(() => import('src/views/DashboardAppView/Preferences')),
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to='/404' />,
    },
  ],
};

export default AppRoutes;
