import { createSlice } from '@reduxjs/toolkit';
import runAxios from 'src/utils/axiosPetitions';

const initialState = {
  isLoading: false,
  error: false,
  configuration: [],
  users: [],
  clients: [],
  tiposTickets: [],
  areas: [],
  departamentos:[]
};

const slice = createSlice({
  name: 'initialConfiguration',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setClientes(state, action) {
      state.clients = action.payload;
    },
    setTipoTicket(state, action) {
      state.tiposTickets = action.payload;
    },
    setAreas(state, action) {
      state.areas = action.payload;
    },
    setDepartamentos(state, action) {
        state.departamentos = action.payload;
      },
  },
});

export default slice.reducer;

export function getUsers() {
  return async (dispatch) => {
    await runAxios('Tickets/getUsuarios', 'get', null, null)
      .then((res) => {
        if (res.internalCode >= 20) {
          alert('Se ha presentado un error al consultar la información de los usuarios del sistema');
          return;
        } else {
          dispatch(slice.actions.setUsers(res.responseDetail));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getClientes() {
  return async (dispatch) => {
    await runAxios('clients/getCombobox', 'get', null, null)
      .then((res) => {
        if (res.internalCode >= 20) {
          alert('Se ha presentado un error al consultar la información de los usuarios del sistema');
          return;
        } else {
          dispatch(slice.actions.setClientes(res.responseDetail));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getTipoTicket() {
  return async (dispatch) => {
    await runAxios('Tickets/getTipoTicket', 'get', null, null)
      .then((res) => {
        if (res.internalCode >= 20) {
          alert('Se ha presentado un error al consultar la información de los usuarios del sistema');
          return;
        } else {
          dispatch(slice.actions.setTipoTicket(res.responseDetail));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getAreas() {
  return async (dispatch) => {
    await runAxios('Tickets/getAreas', 'get', null, null)
      .then((res) => {
        if (res.internalCode >= 20) {
          alert('Se ha presentado un error al consultar la información de los usuarios del sistema');
          return;
        } else {
          dispatch(slice.actions.setAreas(res.responseDetail));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}


export function getDepartamentos() {
    return async (dispatch) => {
      await runAxios('clients/getCiudDep', 'get', null, {simple:true})
        .then((res) => {
          if (res.internalCode >= 20) {
            alert('Se ha presentado un error al consultar la información de los usuarios del sistema');
            return;
          } else {
            dispatch(slice.actions.setDepartamentos(res.responseDetail));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }