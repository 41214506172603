import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store, persistor } from './redux/store';
import routes, { renderRoutes } from 'src/routes';
import ScrollToTop from 'src/components/ScrollToTop';
import LoadingScreen from 'src/components/LoadingScreen';
import { PersistGate } from 'redux-persist/lib/integration/react';
import NotistackProvider from 'src/components/NotistackProvider';
import 'src/theme/css/styles.css';
import deMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import './styles.css';
// ----------------------------------------------------------------------

const history = createBrowserHistory();
function App() {
  loadMessages(deMessages);
  locale('es');
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <NotistackProvider>
            <Router history={history}>
              <ScrollToTop />
              {renderRoutes(routes)}
            </Router>
          </NotistackProvider>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;