import { useSelector, useDispatch } from 'react-redux';
import { login } from 'src/redux/slices/authJwt'


const useAuth = () => {
  const dispatch = useDispatch();
    const { user, isLoading, isAuthenticated } = useSelector( (state) => state.authJwt );

  return {
    login: async ({user, password})=>{
      dispatch(login({user: user, password: password}));
    }, 
    isLoading:isLoading,
    isAuthenticated: isAuthenticated,
    user: user
  };
};

export default useAuth;
